<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap pa-0 ma-0>
      <v-flex xs12 sm12 pa-0>
        <v-card outlined>
          <v-card-title class="primary white--text">
            <span class="title" v-if="grade_name">Sections ({{ grade_name }})</span>
            <span class="title" v-else>Sections</span>
            <v-spacer></v-spacer>
            <add-button
              style="color: #fff !important"
              permission="section-create"
              @action="
                (form.dialog = true), $refs.form ? $refs.form.reset() : ''
              "
              >New Section
            </add-button>
          </v-card-title>

          <v-container>
            <alert-message
              v-if="duplicateTeacher"
              type="error"
              :extra-small="true"
              title="Duplicate Class Teacher"
            >
              <template v-slot:message>
                Duplicate class teacher "<strong>{{
                  duplicateTeacher.class_teacher.name
                }}</strong
                >" also found in
                <a href="" @click.prevent="duplicateSection(duplicateTeacher)"
                  ><strong
                    >{{ duplicateTeacher.grade.grade }} /
                    {{ duplicateTeacher.name }}</strong
                  ></a
                >
              </template>
            </alert-message>
          </v-container>
          <v-data-table
            :headers="headers"
            hide-default-footer
            :items="form.items.data"
            :options.sync="pagination"
            :loading="form.loading"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td>{{ ++index }}</td>
                <td class="text-xs-left">
                  <strong>{{ item.name }}</strong> <br />
                  <small
                    >{{
                      item.class_teacher ? item.class_teacher.full_name : ""
                    }}
                  </small>
                </td>
                <td class="text-xs-left">{{ item.code }}</td>
                <td class="text-right">
                  <edit-button
                    permission="section-update"
                    @agree="update(item)"
                  />
                  <!-- <delete-button
                    permission="section-delete"
                    @agree="
                      (form.fireFetch = false),
                        form.delete(item.id),
                        (form.dialog = false),
                        destroyLocalStorage()
                    "
                  /> -->
                  <delete-button
                    :disabled="disable_section_delete"
                    permission="section-delete"
                    @agree="deleteSection(item.id)"
                  />
                </td>
              </tr>
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="warning"
            >
              Your search for "{{ search }}" found no results.
            </v-alert>
          </v-data-table>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning" text @click="modalClose()">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
      <v-dialog v-model="form.dialog" persistent max-width="300px">
        <v-card>
          <v-card-title class="primary white--text">
            <span class="title">Add/Update</span>
          </v-card-title>
          <v-card-text class="pb-1 pt-4">
            <v-form
              ref="form"
              @submit.prevent="save"
              @keydown.native="form.errors.clear($event.target.name)"
              @keyup.enter="save"
              lazy-validation
            >
              <v-text-field
                outlined
                dense
                autocomplete="off"
                label="Section Name*"
                required
                class="pa-0"
                v-model="form.name"
                name="name"
                :error-messages="form.errors.get('name')"
              />
              <v-text-field
                outlined
                dense
                autocomplete="off"
                label="Section Code*"
                required
                class="pa-0"
                v-model="form.code"
                name="code"
                :error-messages="form.errors.get('code')"
              />
              <v-autocomplete
                outlined
                dense
                v-model="form.teacher_id"
                :items="items"
                :loading="isLoading"
                :search-input.sync="search"
                :append-icon="form.teacher_id ? 'clear' : ''"
                @click:append="onAppendIconClick()"
                hide-no-data
                item-text="Name"
                :error-messages="form.errors.get('teacher_id')"
                item-value="id"
                label="Class teacher"
                placeholder="Start typing to Search"
                return-object
              ></v-autocomplete>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="warning"
              text
              @click="(form.dialog = false), form.reset()"
              >Close</v-btn
            >
            <v-btn color="success" text @click="save">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";
import Mixins from "@/library/Mixins";

export default {
  mixins: [Mixins],
  data: () => ({
    isLoading: false,
    teachers: [],
    entries: [],
    form: new Form(
      {
        name: "",
        code: "",
        batch_id: "",
        grade_id: "",
        teacher_id: "",
      },
      "/api/section"
    ),
    search: "",
    headers: [
      { text: "#", align: "center", value: "id", width: 10, sortable: false },
      { text: "Name", align: "left", value: "name" },
      { text: "Code", align: "left", value: "code" },
      { text: "Action", align: "right", sortable: false, width: 200 },
    ],
    chartData: [],
    chartOptions: {
      title: {
        text: "Section-Wise Students",
        align: "center",
      },
      legend: {
        show: false,
        floating: true,
      },
      labels: [],
    },
    duplicateTeacher: null,
  }),

  computed: {
    ...mapState(["batch"]),
    items() {
      return this.entries.map((entry) => {
        return Object.assign({}, entry, { Name: entry.name });
      });
    },
  },

  watch: {
    pagination: function () {
      this.get();
    },
    "form.dialog": function (value) {
      if (value) {
        this.form.batch_id = this.batch.id;
        // this.form.grade_id = this.$route.params.gradeId;
        this.form.grade_id = this.grade_id;
      }
    },
    batch: function (value) {
      this.get();
    },
    search(val) {
      if (!val) return;
      if (this.isLoading) return;

      this.isLoading = true;
      this.$rest
        .get("api/user-search/slim?type=teacher&search=" + val)
        .then(({ data }) => {
          this.entries = data.data;
        })
        .catch((err) => {})
        .finally(() => (this.isLoading = false));
    },
  },
  props: {
    grade_id: {
      required: false,
    },
    filtered_section_data: {
      required: false,
    },
    section_dialog: {
      required: false,
    },
    grade_name:{
      required:false
    },
    disable_section_delete:{
      default:false
    }
  },
  methods: {
    // get(params) {
    //   let extraParams = "grade=" + 20;
    //   if ([null, undefined].includes(this.batch.id)) return;
    //   let query = [null, undefined].includes(params)
    //     ? this.queryString(extraParams)
    //     : params;
    //   this.form
    //     .get(null, query)
    //     .then((res) => {
    //       this.duplicateTeacher = res.data.duplicate;
    //     })
    //     .finally((res) => {
    //       this.setChart();
    //     });
    // },
    modalClose() {
      this.$emit("modalClose");
    },
    get() {
      this.form.items.data = this.filtered_section_data[0]["sections"];
    },
    update(item) {
      let teacherId = item.teacher_id;
      if (item.class_teacher) {
        this.entries = [
          {
            name: item.class_teacher.full_name,
            id: teacherId,
            Name: item.class_teacher.full_name,
          },
        ];
        // this.entries ={
        //   name: item.class_teacher.full_name,
        //   id: teacherId,
        //   Name: item.class_teacher.full_name,
        // }
      }
      this.form.teacher_id = teacherId;
      this.form.fireFetch = false;
      this.form.edit(item);
    },

    save() {
      this.form.grade_id = this.grade_id;
      if (this.form.teacher_id) this.form.teacher_id = this.form.teacher_id.id;
      this.form.fireFetch = false;
      this.form
        .store()
        .then((res) => {
          if (res.status == 200) {
            this.form.dialog = false;
            this.destroyLocalStorage();
          }
        })
        .catch((e) => {
          this.$events.fire("notification", {
            message: e.data.errors.teacher_id[0],
            status: "error",
          });
        });
    },
    // saveSection() {
    //   this.form.grade_id = this.grade_id;
    //   if (this.form.teacher_id) this.form.teacher_id = this.form.teacher_id.id;
    //   this.$rest
    //     .post("/api/section", {
    //       name: this.form.name,
    //       code: this.form.code,
    //       batch_id: this.form.batch_id,
    //       grade_id: this.form.grade_id,
    //       teacher_id: this.form.teacher_id,
    //     })
    //     .then((res) => {
    //       console.log(res);
    //       if (res.status == 200) {
    //         this.$events.fire("notification", {
    //           message: res.data.msg,
    //           status: "success",
    //         });
    //         this.form.dialog = false;
    //         this.destroyLocalStorage();
    //       }
    //     })
    //     .catch((err) => {
    //       this.$events.fire("notification", {
    //         message: err.data.errors.teacher_id[0],
    //         status: "error",
    //       });
    //     });
    // },
    deleteSection(id) {
      this.$rest
        .delete("/api/section/" + id)
        .then((res) => {
          if (res.status == 200) {
            this.$events.fire("notification", {
              message: res.data.message,
              status: "success",
            });
            this.form.dialog = false;
            this.destroyLocalStorage();
            this.get();
          }
        })
        .catch((err) => {});
    },
    destroyLocalStorage() {
      this.$emit("destroyLocalStorage");
    },

    setChart(data) {
      if (data == null) {
        data = this.form.items.data;
      }

      let labels = data.map((item) => {
        return item.name;
      });

      this.chartOptions.labels = labels;

      this.chartData = data.map((item) => {
        return item.students;
      });
    },
    duplicateSection(section) {
      this.$router.push({
        name: "grades-detail",
        params: { gradeId: section.grade_id },
      });
    },
    onAppendIconClick(){
      this.form.teacher_id = ''
    }
  },
};
</script>
<style lang="scss" scoped></style>
